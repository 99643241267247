.video-card {
  border-radius: 5px;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  overflow: hidden;

  .prevideo-window {
    color: white;
    margin-bottom: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .coming-soon {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    margin-top: 20px;
  }

  .video-player-container {
    width: 100%;
    height: 100%;
  }
}

.host-section {
  background-color: white;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h4 {
    margin: 0;
  }

  .host-tag {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
