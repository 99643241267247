.login-create-account-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  .login-form {
    width: 50%;
  }
}
