.landing-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    height: 100%;

    .surf-cams-text {
        margin-top: 60px;
        margin-bottom: 20px;
    }
}