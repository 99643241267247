// .custom-carousell-container {
//     margin-bottom: 40px;
// }

// .play-surf-cam-container {
//     border-radius: 8px;
//     position: absolute;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 100%;
//     height: 100%;

//     .coming-soon-icon {
//         position: absolute;
//         top: 45%;
//         left: 50%;
//         transform: translate(-50%, -50%);
//         // font-size: 48px;
//     }
// }
.embla {
    overflow: hidden;
}

.embla__container {
    display: flex;
}

.embla__slide {
    flex: 0 0 100%;
    min-width: 0;
    max-width: 280px;
}

.carousel-container {
    width: 100%;
    display: flex;
}

.carousel-item {
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-size: cover;
    height: 192px;
    width: 256px;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;

    .legend {
        position: absolute;
        z-index: 1;
        text-transform: capitalize;
        color: white;
        top: 165px;
        background-color: rgba(0, 0, 0, 0.3);
        width: 100%;
        text-align: center;
    }

    .video-thumbnail {
        border-radius: 8px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .coming-soon-thumbnail {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        .coming-soon-icon {
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            // font-size: 48px;
        }
    }
}

.tab-content {
    width: 100%;
    display: flex;
    flex-direction: row;

    .surftown-button {
        margin-right: 20px;
    }
}