.tab-window {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 95%;
  box-sizing: border-box;

  .surf-cam-player-id-container {
    margin-bottom: 40px;
    position: relative;
    top: -40px;
  }

  .tab-section {
    width: 100%;
    padding: 60px;
  }

  .app-bar {
    margin-bottom: 10px;
  }

  .description {
    margin-top: 20px;
    margin-bottom: 100px;
    width: 80%;
    color: black;
  }
}

@media (max-width: 768px) {
  .tab-window {
    width: 95%;
    /* Set to full width for screen sizes less than a tablet */
  }
}
