.container {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 3rem;
  margin-bottom: 0px;
}

.subtitle {
  font-size: 1.5rem;
  margin-right: 10px;
}

.content {
  text-align: center;
}

.content-title {
  font-size: 2rem;
  margin-bottom: 20px;
}

.content-description {
  font-size: 1.2rem;
  margin-bottom: 40px;
}

.content-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.navbar-spacer {
  margin-top: 56px;
}